import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate, useLocation, Link } from 'react-router-dom';
import loginImage from './LoginImage.png';
import errorImage from './error.png'; // Hata resmi
import Person from './person';
import Sidebar from './Sidebar'; 
import UserForm from './UserForm';
import IzinSayfasi from './IzinSayfasi';
import UserManagement from './UserManagement';
import Login from './login';
import HomePage from './homepage';
import Kurumbilgi from './Kurumbilgi';
import Hakedis from './hakedis';

// NotFoundPage bileşeni: 404 hata sayfası
const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <img src={errorImage} alt="Error" className="w-1/2 mb-4" />
      <p className="text-lg">Bir hata oluştu. Lütfen tekrar giriş yapınız.</p>
      <button
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
        onClick={() => navigate('/login')}
      >
        Giriş Sayfasına Dön
      </button>
    </div>
  );
};

// Home bileşeni: Ana sayfa
const Home = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-3xl font-bold mb-4">İDEKOD Personel Takip Sistemine Hoş Geldiniz</h1>
      <Link to="/login" className="text-blue-500 hover:text-blue-700 transition">
        Giriş Yapmak İçin Tıklayınız
      </Link>
    </div>
  );
};

// MainContent bileşeni: Ana içerik yapısı
const MainContent = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true); // Sidebar açık/kapalı durumu
  const location = useLocation(); // Aktif rotayı almak için

  // Sidebar'ın gizlenmesi gereken rotalar
  const hideSidebarRoutes = ['/', '/login'];
  const isSidebarHidden = hideSidebarRoutes.includes(location.pathname); // Aktif rota sidebar gizlenmeli mi?

  return (
    <div className="flex h-screen">
      {/* Eğer rotalar hideSidebarRoutes içinde değilse Sidebar'ı göster */}
      {!isSidebarHidden && (
        <div className={`transition-transform duration-300 ease-in-out ${isSidebarOpen ? 'w-64' : 'w-16'} bg-gray-800`}>
          <Sidebar isOpen={isSidebarOpen} toggleSidebar={() => setSidebarOpen(!isSidebarOpen)} />
        </div>
      )}
      <div className={`flex-grow bg-gray-100 p-4 ${isSidebarHidden ? 'w-full' : 'w-full md:w-auto'}`}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/company" element={<Kurumbilgi />} />
          <Route path="/pjoin" element={<UserForm />} />
          <Route path="/pedit" element={<UserManagement />} />
          <Route path="/person" element={<Person />} />
          <Route path="/permission" element={<IzinSayfasi />} />
          <Route path="/hakedis" element={<Hakedis />} />
          <Route path="/help" element={<div>İletişim Sayfası</div>} />
          <Route path="/404" element={<NotFoundPage />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </div>
    </div>
  );
};

// App bileşeni: Ana uygulama yapısı
const App = () => {
  return (
    <Router>
      <MainContent />
    </Router>
  );
};

export default App;
