import React, { useState, useEffect  } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import './App.css';
import loginImage from './LoginImage2.png';
import errorImage from './error.png'; // Hata resmi ekleyin
import Person from './person';
import Sidebar from './Sidebar'; 
import UserForm from './UserForm';
import IzinSayfasi from './IzinSayfasi';
import UserManagement from './UserManagement';


const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };
const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [company, setCompany] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
  
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://api.idekod.com.tr/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password, company })
            });
            const data = await response.json();
            if (data.success) {
                localStorage.setItem('username', username);
                localStorage.setItem('role', data.role); // Yetki alanını da saklıyoruz
                localStorage.setItem('company', company);
                navigate('/home');
            } else {
                setError('Hatalı kullanıcı adı, şifre veya kurum kısa kodu.');
            }
        } catch (error) {
            console.error('There was an error logging in!', error);
        }
    };
  
    return (
        <div className="flex h-screen">
        <div className="w-1/2 bg-white flex justify-center items-center">
            <img src={loginImage} alt="Login Illustration" className="max-w-full max-h-full" />
        </div>
        <div className="w-1/2 flex flex-col justify-center items-center bg-white p-8">
            <form onSubmit={handleSubmit} className="w-full max-w-md space-y-6">
                <h2 className="text-2xl font-bold text-gray-800 text-center">Giriş Yap</h2>
                <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Kullanıcı Adı:
                    </label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Şifre:
                    </label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Kurum Kısa Kodu:
                    </label>
                    <input
                        type="text"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        required
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <button
                    type="submit"
                    className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    Giriş Yap
                </button>
                {error && (
                    <p className="text-red-500 text-xs italic text-center mt-4">{error}</p>
                )}
            </form>
        </div>
    </div>
);
};
  
export default Login;