import React, { useState, useEffect } from 'react';

const Kurumbilgi = () => {
  const [kurumBilgi, setKurumBilgi] = useState({
    kurumad: '',
    logourl: '',
    updatetime: '',
    updateuser: ''
  });
  const [logoFile, setLogoFile] = useState(null);
  const [rname, setRname] = useState('');
  const [kurumAd, setKurumAd] = useState('');  // Kurum adı
  const [logo, setLogo] = useState('');  
  const company = localStorage.getItem('company');
  const username = localStorage.getItem('username');



  useEffect(() => {
    // Kurum bilgisi için API çağrısı
    fetch(`https://api.idekod.com.tr/api/kurumbilgi/${company}`)
      .then((response) => response.json())
      .then((data) => {
        setKurumAd(data.kurumad);   // Kurum adını alıyoruz
        setLogo(data.logourl);         // Logo'yu base64 formatında alıyoruz
      })
      .catch((error) => {
        console.error('Kurum bilgisi alınırken hata oluştu:', error);
      });
  }, [company]);

  // Resmi base64 formatına çevirme
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    
    reader.onloadend = () => {
      const base64String = reader.result;
      
      // Base64 boyutunu hesaplama
      const sizeInBytes = (base64String.length * (3/4)) - (base64String.endsWith('==') ? 2 : 1);
      const sizeInMB = sizeInBytes / (1024 * 1024);
      
      console.log(`Base64 boyutu: ${sizeInMB.toFixed(2)} MB`);
      
      if (sizeInMB > 5) {  // Örneğin, 5 MB sınırı
        alert('Resim çok büyük. Lütfen daha küçük bir resim seçin.');
      } else {
        setLogoFile(base64String); // Base64 formatını state'e set et
      }
    };
    
    reader.readAsDataURL(file);
  };

  // Verilerin güncellenmesi ve API'ye gönderilmesi
  const handleUpdate = async () => {
    const company = localStorage.getItem('company'); // localStorage'den company bilgisini al
    const username = localStorage.getItem('username'); // localStorage'den kullanıcı adını al
  
    const updatedData = {
      ...kurumBilgi,
      logourl: logoFile || kurumBilgi.logourl, // Eğer yeni resim seçilmişse onu kullan
      updatetime: new Date().toISOString().slice(0, 19).replace('T', ' '),    // İşlem tarihi
      updateuser: username,                    // Güncellemeyi yapan kullanıcı
      company                                  // API'ye company bilgisini gönder
    };
  
    const confirmUpdate = window.confirm('Bilgileri güncellemek istiyor musunuz?');
    
    if (confirmUpdate) {
      try {
        const response = await fetch('https://api.idekod.com.tr/api/kurumbilgiguncel', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedData),
        });
  
        if (!response.ok) {
          throw new Error('Güncelleme başarısız');
        }
  
        alert('Kurum bilgileri başarıyla güncellendi!');
      } catch (error) {
        console.error('Kurum bilgileri güncellenirken hata oluştu:', error);
      }
    }
  };
  

  return (
    <div className="container mx-auto p-4">
      
      <h1 className="text-2xl font-bold mb-4">Kurum Bilgileri</h1>
      <h2 className="text-xl font-medium">Kurum Adınız:   {kurumAd}</h2><br></br>
      <h2 className="text-xl font-medium">Kurum Logonuz:</h2>
      {logo && <img src={`${logo}`} alt="Logo" className="w-20 h-20 mr-2 rounded-full" />}<br></br>
     
     
      <div className="mb-4">
        <label className="block text-sm font-semibold  ">Kurum Adı</label>
        <input
          type="text"
          value={kurumBilgi.kurumad}
          onChange={(e) => setKurumBilgi({ ...kurumBilgi, kurumad: e.target.value })}
          className="w-full px-3 py-2  border border-gray-300 rounded-lg"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-semibold">Logo Yükle</label>
        <input type="file" onChange={handleFileChange} className="w-full py-2" />
        {logoFile && (
          <img src={logoFile} alt="Seçilen logo" className="mt-4 h-24 w-auto" />
        )}
      </div>
      <button
        onClick={handleUpdate}
        className="bg-blue-500 text-white px-4 py-2 rounded-lg"
      >
        Güncelle
      </button>
    </div>
  );
};

export default Kurumbilgi;
