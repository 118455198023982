import React, { useState, useEffect  } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate } from 'react-router-dom';
//import './App.css';
import loginImage from './LoginImage2.png';
import errorImage from './error.png'; // Hata resmi ekleyin
import Person from './person';
import Sidebar from './Sidebar'; 
import UserForm from './UserForm';
import IzinSayfasi from './IzinSayfasi';
import UserManagement from './UserManagement';
import Login from './login';

const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };
  
  
  
  const HomePage = () => {
    const [personelData, setPersonelData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]); // Bugünün tarihi varsayılan olarak
    const navigate = useNavigate();
  
  
    const handleLogout = () => {
      console.log('Çıkış yapılıyor...'); 
      localStorage.removeItem('username');
      localStorage.removeItem('role');
      navigate('/');
    };
  
  
    useEffect(() => {
      const fetchPersonelData = async () => {
        try {
          const response = await fetch(`https://api.idekod.com.tr/login/person?date=${selectedDate}`);
          
          if (!response.ok) {
            const errorData = await response.text();
            throw new Error(`Network response was not ok: ${response.status} ${response.statusText} - ${errorData}`);
          }
  
          const data = await response.json();
          console.log('Fetched Data:', data);
          setPersonelData(data);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };
  
      fetchPersonelData();
    }, [selectedDate]);
  
    const handleDateChange = (e) => {
      setSelectedDate(e.target.value);
    };
  
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
  
    return (
        <div className="home-container bg-gray-100 min-h-screen flex items-center justify-center">
       
        
       <main className="bg-white shadow-md rounded-lg p-8 w-full  max-w-lg ">
          <h1>Personel Giriş/Çıkış Bilgileri</h1>
          <label htmlFor="date-picker">Tarih Seç:</label>
          <input
            className='input-date'
            type="date"
            id="date-picker"
            value={selectedDate}
            onChange={handleDateChange}
            max={new Date().toISOString().split('T')[0]} 
          />
      
          <table>
            <thead>
              <tr>
              <th>AD SOYAD</th>
                
                <th>TARİH</th>
                <th>GİRİŞ SAATİ</th>
                <th>ÇIKIŞ SAATİ</th>
                <th>DURUM</th>
                {/* Diğer sütunlar */}
              </tr>
            </thead>
            <tbody>
    {personelData.length > 0 ? (
      personelData.map(personel => {
        // DURUM değerine göre sınıf ve metin belirleme
        const durumClass = personel.DURUM === 2 ? 'exit' : 'entry';
        const durumText = personel.DURUM === 2 ? 'Çıkış Yaptı' : 'Giriş Yaptı';
        
        return (
          <tr key={personel.id}>
            <td>{personel.RNAME}</td>
            
            <td>{formatDate(personel.DATEG)}</td>
            <td>{personel.TIMEG}</td>
            <td>{personel.TIMEC}</td>
            <td className={durumClass}>{durumText}</td>
           
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="5">Bu tarihte kaydınız yoktur.</td>
      </tr>
    )}
  </tbody>
          </table>
        </main>
      </div>
    );
  };

  export default HomePage;