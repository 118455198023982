import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar'; 

const UserForm = () => {
  const [realname, setRealname] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [status, setStatus] = useState('');
  const [company, setCompany] = useState('');
  const [eposta, setEposta] = useState('');
  const [excelFile, setExcelFile] = useState(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const navigate = useNavigate();


  const handleLogout = () => {
      localStorage.removeItem('username');
      navigate('/');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userData = {
        realname,
      username,
      password,
      phone,
      company,
      status,
      eposta
    };

    try {
      const response = await fetch('https://api.idekod.com.tr/api/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
      });

      if (response.ok) {
        alert('Kullanıcı başarıyla eklendi!');
      } else {
        alert('Kullanıcı ekleme başarısız oldu.');
      }
    } catch (error) {
      console.error('Hata:', error);
    }
  };

  const handleFileChange = (e) => {
    setExcelFile(e.target.files[0]);
  };


  const handleExcelSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file', excelFile);

    try {
      const response = await fetch('https://api.idekod.com.tr/api/users/excel', {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        alert('Kullanıcılar başarıyla eklendi!');
      } else {
        alert('Excel dosyasından kullanıcı ekleme başarısız oldu.');
      }
    } catch (error) {
      console.error('Hata:', error);
    }
  };

  return (
<div className="home-container bg-gray-100 min-h-screen flex items-center justify-center">
  <main className="bg-white shadow-md rounded-lg p-8 w-full max-w-lg">
    <h1 className="text-2xl font-bold mb-6 text-center">Kullanıcı Ekle</h1>
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2">Kullanıcı Ad Soyad:</label>
        <input
          type="text"
          value={realname}
          onChange={(e) => setRealname(e.target.value)}
          required
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2">Kullanıcı ID:</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2">Şifre:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2">Telefon:</label>
        <input
          type="text"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2">Durum:</label>
        <input
          type="text"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          required
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2">Şirket Kısa Kod:</label>
        <input
          type="text"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
          required
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2">E-posta:</label>
        <input
          type="text"
          value={eposta}
          onChange={(e) => setEposta(e.target.value)}
          required
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <button
        type="submit"
        className="w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200"
      >
        Kaydet
      </button>
    </form>

    <h2 className="text-xl font-bold mt-8 mb-4 text-center">Excelden Aktar</h2>
    <form onSubmit={handleExcelSubmit}>
      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileChange}
        required
        className="mb-4 w-full py-2 border border-gray-300 rounded-lg"
      />
      <button
        type="submit"
        className="w-full bg-green-500 text-white font-semibold py-2 rounded-lg hover:bg-green-600 transition duration-200"
      >
        Excelden Aktar
      </button>
    </form>
  </main>
</div>

  );
};

export default UserForm;
