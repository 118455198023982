import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Hakedis() {
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState('aktif');
  const [editingUser, setEditingUser] = useState(null);  // Düzenlenen kullanıcı
  const [editedHakedis, setEditedHakedis] = useState('');  // Düzenlenen hakedis değeri
  const role = localStorage.getItem('role');
  const username = localStorage.getItem('username');
  const [excelFile, setExcelFile] = useState(null);
  
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('username');
    navigate('/');
  };

  // Sayfa açıldığında aktif kullanıcıları getir
  useEffect(() => {
    fetchUsers('aktif');
  }, []);

  // API'den kullanıcıları çek
  const fetchUsers = (userType) => {
    let apiUrl = '';
    if (userType === 'aktif') apiUrl = 'https://api.idekod.com.tr/hakedis';
    else if (userType === 'bekleyen') apiUrl = 'https://api.idekod.com.tr/bekleyen';
    else if (userType === 'pasif') apiUrl = 'https://api.idekod.com.tr/pasif';

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setUsers(data);
        } else {
          setUsers([]);
        }
      })
      .catch((error) => console.error('Error:', error));
  };

  // Combobox'ta seçim yapıldığında filtreyi değiştir ve kullanıcıları tekrar getir
  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setFilter(selectedFilter);
    fetchUsers(selectedFilter);
  };

  // Düzenleme moduna geç
  const startEditing = (user) => {
    setEditingUser(user.TELNO);
    setEditedHakedis(user.hakedis);
  };

  // Düzenlemeyi onayla
  const confirmEdit = (user) => {
    const confirmed = window.confirm('Değişiklikleri kaydetmek istiyor musunuz?');
    if (confirmed) {
      // API'ye güncellenen veriyi gönder
      fetch('https://api.idekod.com.tr/hakedisupdate', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ telno: user.TELNO, hakedis: editedHakedis }),
      })
      .then((response) => response.json())
      .then(() => {
        fetchUsers(filter); // Güncellenen veriyi tekrar çek
        cancelEditing(); // Düzenleme modunu kapat
      })
      .catch((error) => console.error('Error:', error));
    }
  };

  // Düzenleme modunu iptal et
  const cancelEditing = () => {
    setEditingUser(null);
    setEditedHakedis('');
  };

  const handleFileChange = (e) => {
    setExcelFile(e.target.files[0]);
  };


  const handleExcelSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file', excelFile);

    try {
      const response = await fetch('https://api.idekod.com.tr/api/users/excelhakedis', {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        alert('Kullanıcılar başarıyla eklendi!');
        
      } else {
        alert('Excel dosyasından kullanıcı ekleme başarısız oldu.');
      }
    } catch (error) {
      console.error('Hata:', error);
    }
  };

  return (
    <div className="p-6 space-y-8">
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="bg-gray-600 p-4 text-black">
        <h1 className="text-xl font-semibold text-center">Kullanıcı Listesi</h1>
          <h2 className="text-lg font-bold mt-8 mb-4">Excelden Aktar</h2>
    <form onSubmit={handleExcelSubmit}>
      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileChange}
        required
        className="mb-4 w-full py-2 border border-gray-300 rounded-lg"
      />
      <button
        type="submit"
        className="w-full bg-green-500 text-white font-semibold py-2 rounded-lg hover:bg-green-600 transition duration-200"
      >
        Excelden Aktar
      </button>

      
    </form>
 
        </div>
        <div>
      <h1 className="text-lg font-bold mt-8 mb-4" >Örnek Excel Dosyasını İndir</h1>
      <a 
        href="http://pdks.idekod.com.tr/indirmeler/hakedis.xlsx"
        download="hakedis.xlsx"
        target="_blank"
        rel="noopener noreferrer"
        className="text-lg font-  mt-8 mb-4"
      >
        Excel Dosyasını İndir
      </a>
    </div>
        <table className="min-w-full table-auto">
          <thead>
            <tr className="bg-gray-200 text-gray-700">
              <th className="px-4 py-2 text-left">Adı</th>
              <th className="px-4 py-2 text-left">Telefon</th>
              <th className="px-4 py-2 text-left">Durum</th>
              <th className="px-4 py-2 text-left">Başlangıç Tarihi</th>
              <th className="px-4 py-2 text-left">Hak Ediş</th>
              <th className="px-4 py-2 text-left">İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={user.TELNO} className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} text-gray-700`}>
                <td>{user.RNAME}</td>
                <td>{user.TELNO}</td>
                <td>{user.STATE == 0 ? 'Onay Bekliyor' : user.STATE == 1 ? 'Aktif Kullanıcı' : 'Pasif Kullanıcı'}</td>
                <td>{user.girist}</td>

                {/* Hak Ediş Alanı */}
                <td>
                  {editingUser === user.TELNO ? (
                    <input
                      type="text"
                      value={editedHakedis}
                      onChange={(e) => setEditedHakedis(e.target.value)}
                      className="border border-gray-400 rounded px-2 py-1"
                    />
                  ) : (
                    user.hakedis
                  )}
                </td>

                {/* İşlemler (Kalem, Onayla, İptal) */}
                <td>
                  {editingUser === user.TELNO ? (
                    <>
                      <button
                        className="bg-green-500 text-white px-2 py-1 rounded mr-2 hover:bg-green-600"
                        onClick={() => confirmEdit(user)}
                      >
                        Onayla
                      </button>
                      <button
                        className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
                        onClick={cancelEditing}
                      >
                        İptal
                      </button>
                    </>
                  ) : (
                    <button
                      className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
                      onClick={() => startEditing(user)}
                    >
                      ✏️ Düzenle
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Hakedis;
